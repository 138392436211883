module.exports = [{
      plugin: require('../.yarn/__virtual__/@sentry-gatsby-virtual-9b00f2c173/0/cache/@sentry-gatsby-npm-7.119.0-1470dc9623-62aa84a33f.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://eb3773c69377443b98fa857cde350722@o1362387.ingest.sentry.io/6653811","sampleRate":1},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-9bd6586aab/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-00c76451d0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Star Trek Timelines DataCore","short_name":"DataCore","start_url":"/","background_color":"#3A3F44","theme_color":"#272B30","display":"standalone","icon":"src/images/logo.svg","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"eff1eba8873af42819e36d7b4b245dec"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-1f953db2d3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
